<div *ngIf="options">
  <input type="text"
         [disabled]="options.readOnly"
         placeholder="{{options.toolTip}}"
         id="{{options.elementId}}"
         (blur)="onInputBlur()"
         (focus)="onInputFocus()"
         #ac
  >
</div>


<!-- unit list -->
<ng-container *ngIf="hasUnitAutocomplete">
  <lhc-autocomplete 
    [options]="options" 
    [(dataModel)]="item.unit"
    (onBlurFn)="lhcDataService.activeRowOnBlur(item)"
    (onFocusFn)="lhcDataService.setActiveRow(item)"
    [isFormReady]="lhcDataService.isFormReady()"
    ></lhc-autocomplete>
</ng-container>


<ng-container *ngIf="item">
  <div id="{{item._elementId}}">
  <!--     aria-describedby="help-{{ item._elementId }}"-->
  <!--     aria-labelledby="{{labelledBy}}">-->
    <div *ngIf="!item.value">
      <input [name]="item._text" type="file" class="lhc-attachment-upload"
             #fileInput
             (change)="onChange($event)"
             placeholder="{{item._toolTip}}" [disabled]="item._readOnly"
             id="{{'file-'+item._elementId}}" (focus)="lhcDataService.setActiveRow(item)"
             (blur)="lhcDataService.activeRowOnBlur(item)" />
      <button type="button" class="lhc-attachment-button toggle-attachment-fields" title="{{item._useURL ?
       'Hide URL field' : 'Show URL field'}}"
              (click)="item._useURL = !item._useURL">&#x21af;</button>
      <div *ngIf="item._useURL">
        <label>URL for file:</label>
        <input type="text" [disabled]="item._readOnly" class="lhc-attachment-url"
               [(ngModel)]="item._attachmentURL" placeholder="URL for retrieving file" (focus)="lhcDataService.setActiveRow(item)"
               (blur)="lhcDataService.activeRowOnBlur(item)" >
        <input type="text" [disabled]="item._readOnly" class="lhc-attachment-name" (focus)="lhcDataService.setActiveRow(item)"
               (blur)="lhcDataService.activeRowOnBlur(item)" [(ngModel)]="item._attachmentName" placeholder="File name (optional)">
        <button type="button" class="lf-float-button attach-button" (click)="createAttachment(item)">
          Attach URL{{item._fileInfo ? ' and file data': ''}}</button><br>
        Note:  The URL you enter will not be downloaded or
        verified, but simply copied into your response.  Please ensure
        that it works.
      </div>
    </div>
    <span *ngIf="item.value">
      <a *ngIf="!item.value._progress" button title="{{'Download '+item.value.title}}"
         target=_blank rel="noreferrer" download={{item.title}}
         href="{{item.value.url && !item.value.data ? item.value.url : 'javascript:void(0)'}}" class="download-link"
         (click)="downloadAttachment(item.value, $event)"
      >{{item.value.title || item.value.url || 'Unknown filename'}}</a>
      <span *ngIf="item.value._progress"><label>Download progress:
        <progress value="{{item.value._progress}}"></progress></label></span>
      <button class="lhc-attachment-button lf-remove-attachment" *ngIf="!item._readOnly"
       type="button" title="{{'Remove '+item.value.title}}"
       (click)="removeAttachment(item)">&#x2716;</button>
    </span>
  </div>
</ng-container>

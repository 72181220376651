<ng-container *ngIf="item">
  <nz-switch #nzSwitchComponent
    [nzDisabled]="item._readOnly"
    id="{{item._elementId}}"
    [(ngModel)]="item.value"
    [nzCheckedChildren]="checkedTemplate"
    [nzUnCheckedChildren]="unCheckedTemplate"
    (focus)="lhcDataService.setActiveRow(item)"
    (ngModelChange)="onModelChange($event)"
  >
  </nz-switch>
</ng-container>
<ng-template #checkedTemplate><i nz-icon nzType="check"></i></ng-template>
<ng-template #unCheckedTemplate><i nz-icon nzType="close"></i></ng-template>

<<ng-container *ngIf="lhcFormData">
  <div *ngFor ="let item of lhcFormData.itemList">
    <lhc-watcher 
      [value]="item.value"
      [item]="item"
    >
    </lhc-watcher>
    <ng-container *ngIf="item.units">
      <lhc-watcher
        [value]="item.unit"
        [item]="item"
      >
      </lhc-watcher>
    </ng-container>
  </div>  
</ng-container>
  
  